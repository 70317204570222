<template>
    <div class="md:flex md:items-center md:justify-between pb-10">
        <div class="flex-1 min-w-0">
            <h1 class="text-gray-900"><slot name="title"/></h1>
        </div>
        <slot name="interactive"/>
    </div>
</template>

<script>
export default {

}
</script>
