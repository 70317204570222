<template>
    <div class="flex">
        <dashboard-sidebar :class="{'w-4/6': modal, 'w-full': !modal}">
            <template #over-content>
                <title-bar>
                <template #title>
                    {{ $t('dashboard.chat') }}
                </template>
                <template #interactive>
                    <div class="mt-4 flex md:mt-0 md:ml-4">
                    <button-a @click.native="$router.push({ name: 'dashboard-overview', params: { location: location, terminated: terminated } })" settings="bg-primary cursor-pointer">
                        <icon-chevron-left height="24"/>
                        {{ $t('dashboard.back') }}
                    </button-a>
                    </div>
                </template>
                </title-bar>
            </template>
            <template #content>
                <product-chat-info :modal="modal" @showChat="toggleChat" :orderUuid="orderId" @loc="getLocation" @terminated="getTerminated"/>
            </template>
        </dashboard-sidebar>
        <div :class="{'w-2/6 relative': modal, 'w-0': !modal}">
            <chat-window v-if="modal" :restaurantId="restaurantId" :orderUuid="orderId" @closeChat="modal=false">
            </chat-window>
        </div>
    </div>
</template>

<script>
import DashboardSidebar from '@/components/layout/DashboardSidebar.vue'
import ProductChatInfo from '@/components/dashboard/ProductChatInfo.vue'
import ChatWindow from '@/components/dashboard/ChatWindow.vue'
import TitleBar from '../../components/dashboard/TitleBar.vue'
import ButtonA from '../../components/ui/ButtonA.vue'
import IconChevronLeft from '@/components/icons/IconChevronLeft'

export default {
    name: 'DashboardOverview',
    data () {
        return {
            modal: true,
            location: '',
            terminated: false
        }
    },
    methods: {
        toggleChat () {
            if (this.modal === true) {
                this.modal = false
            } else if (this.modal === false) {
                this.modal = true
            }
        },
        getLocation (location) {
            this.location = location
        },
        getTerminated (terminated) {
            this.terminated = terminated
        }
    },
    components: {
        DashboardSidebar,
        ProductChatInfo,
        TitleBar,
        ChatWindow,
        ButtonA,
        IconChevronLeft
    },
    props: {
    },
    computed: {
        restaurantId () {
            return this.$route.params.restaurantId
        },
        orderId () {
            return this.$route.params.orderId
        }
    }
}
</script>
