<template>
     <div class="flex p-4">
        <span class="font-headline font-bold text-lg text-gray-600">{{lineItem.quantity}}x</span>
        <div class="ml-2 flex flex-col">
            <span class="font-headline font-bold text-lg text-gray-600">{{lineItem.product.title}}
                <span v-if="lineItem.product.sizes.length > 1" class="ml-2 badge inline-flex items-center px-3 py-0.5 rounded-full text-sm font-bold bg-primary transparent">{{lineItem.size.title}}</span>
                </span>
            <span class="text-gray-400 mt-2">
                <div v-for="selection of lineItem.selections" :key="selection.uuid">
                    <span class="text-primary"><strong>{{selection.selection.title}}:</strong></span>
                    {{selection.option.title}}
                </div>

                <div v-for="upgrade of lineItem.upgrades" :key="upgrade.uuid" class="mb-1">
                    <badge settings="bg-gray-600 transparent">
                    {{upgrade.title}}
                            </badge>
                </div>
                <div class="font-headline font-bold text-primary text-2xl">
                </div>
            </span>
        </div>
    </div>
</template>

<script>
import Badge from '../ui/Badge.vue'
import { RenderedLineItem } from 'delivery-boosting-2021-model'
export default {
    name: 'OrderedProducts',
    components: { Badge },
    props: {
        lineItem: {
            type: RenderedLineItem
        }
    },
    computed: {
        catalog () {
            return this.$store.state.catalog
        }
    }
}
</script>
