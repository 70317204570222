<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
<section class="inset-0 overflow-hidden" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <div class="absolute inset-0 overflow-hidden w-full">
    <div class="absolute inset-0" aria-hidden="true"></div>
    <div class="absolute inset-y-0 right-0 flex w-full">
        <div class="w-full">
            <div class="h-full divide-y divide-gray-200 flex flex-col">
                <div class="min-h-0 flex-1 flex flex-col">
                    <div class="p-10">
                        <div class="flex items-start justify-between">
                            <h2 class="h1 font-medium text-gray-900" id="slide-over-title">
                            {{'Chat mit '}} {{order.shipping.firstName}} {{order.shipping.name}}
                            </h2>
                            <div class="ml-3 flex items-center">
                                <button @click="$emit('closeChat')" class="bg-white rounded-md text-gray-500 hover:text-gray-300 focus:outline-none focus:ring-0">
                                    <span class="sr-only">{{ $t('dashboard.close_panel') }}</span>
                                    <!-- Heroicon name: outline/x -->
                                    <svg class="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-chat-scroll="{enable: true, always: true, smooth: true}" class="relative flex-1 px-10 overflow-y-auto">
                        <div v-for="message in renderedMessageList" :key="message.id">
                            <chat-left v-if="message.type === 'user'">
                                <template #message>
                                    {{message.text}}
                                </template>
                                <template #time>
                                    {{message.createdAt.toDate().getDate()}}{{'.'}}{{message.createdAt.toDate().getMonth()+1}}{{', '}}{{message.createdAt.toDate().getHours()}}:{{(message.createdAt.toDate().getMinutes()).toString().padStart(2, '0')}}
                                </template>
                            </chat-left>
                            <chat-right v-if="message.type === 'admin'">
                                <template #message>
                                    {{message.text}}
                                </template>
                                <template #time>
                                    {{message.createdAt.toDate().getDate()}}{{'.'}}{{message.createdAt.toDate().getMonth()+1}}{{', '}}{{message.createdAt.toDate().getHours()}}:{{(message.createdAt.toDate().getMinutes()).toString().padStart(2, '0')}}
                                </template>
                            </chat-right>
                            </div>
                    </div>
                </div>
                <div class="flex-shrink-0 p-10 flex">
                    <div class="flex-grow pr-10">
                        <input type="text" @keyup.enter="addMessage" v-model="chatMessage" id="about" name="about" resize="none" rows="2" class="block w-full border-0 hover:border-0 focus:border-0 focus:ring-0 py-3.5 px-0" style="resize: none;" :placeholder="$t('dashboard.write_message')">
                    </div>
                    <div class="flex-shrink">
                        <button-b @click.native="addMessage" class="bg-primary">{{ $t('dashboard.send') }}</button-b>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</section>
</template>

<script>
import ButtonB from '@/components/ui/ButtonB.vue'
import ChatLeft from '@/components/ui/ChatLeft.vue'
import ChatRight from '@/components/ui/ChatRight.vue'
import { db, FieldValue, messaging } from '@/firebase'
import { Message } from 'delivery-boosting-2021-model'

export default {
    components: { ButtonB, ChatLeft, ChatRight },
    async created () {
        const token = await messaging.getToken({ vapidKey: 'BICklAKhvPWx8zTtzK-eOTq4xceV-4QTYi5_xGRYiNLIaqbtNuYUXBXKSY6pA0EBwQgnFGHM1-0FKPCgj48NiiA' })
        if (token) {
            // console.log('token: ', token)
            await this.tokenCollection.doc(token).set({})
        } else {
            console.log('No registration token available. Request permission to generate one.')
        }
        messaging.onMessage(function (payload) {
            const content = {
                title: payload.notification.title,
                body: payload.notification.body,
                icon: payload.notification.icon
            }
            console.log(content)
        })
    },
    data () {
        return {
            chatMessage: '',
            messages: [],
            messageList: {
                messages: []
            },
            order: {}
        }
    },
    props: {
        restaurantId: {
            type: String
        },
        orderUuid: {
            type: String
        }
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        },
        tokenCollection () {
            return db.doc(`restaurants/${this.restaurantId}/orders/${this.orderUuid}`).collection('tokens')
        },
        renderedMessageList () {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.messageList.messages.sort(function (x, y) {
                return x.createdAt - y.createdAt
            })
        }
    },
    methods: {
        async addMessage () {
            if (this.chatMessage !== '') {
                await this.messageList.addMessage(new Message({
                    type: 'admin',
                    text: this.chatMessage,
                    createdAt: FieldValue.serverTimestamp(),
                    automated: false
                }))
                this.chatMessage = ''
            }
        }
    },
    watch: {
        restaurant: {
            immediate: true,
            async handler () {
                try {
                    this.order = await this.restaurant.getReactiveRenderedOrder(this.orderUuid)
                    this.messageList = await this.restaurant.getMessageList(this.orderUuid)
                } catch (error) {
                    console.warn(error)
                }
            }
        }
    }
}
</script>
