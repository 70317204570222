<template>
    <div class="flex items-center w-full">
        <span class="font-bold mr-5 flex-shrink whitespace-pre">{{ $t('dashboard.ready_in') }}</span>
        <div class="flex items-center border border-gray-200 rounded-md flex-grow justify-between bg-white text-black">
            <div class="flex items-center pr-5">
                <input name="complete-time" id="complete-time" :value="time" :class="{'text-red': color, 'text-gray-600': !color}" class="font-semibold focus:ring-0 focus:text-primary block py-3.5 pl-3.5 border-transparent rounded-md outline-none w-12">
                <label class="font-semibold" for="complete-time">{{ $t('dashboard.min') }}</label>
            </div>
            <div class="flex items-center">
                <button class="btn inline-flex items-center font-bold rounded-md focus:outline-none bg-gray-500 text-gray-500 sm mr-3 transparent w-10 justify-center" v-on:click="add(5)">+5</button>
                <button class="btn inline-flex items-center font-bold rounded-md focus:outline-none bg-gray-500 text-gray-500 sm mr-3 transparent w-10 justify-center" v-on:click="add(10)">+10</button>
                <button class="btn inline-flex items-center font-bold rounded-md focus:outline-none bg-gray-500 text-gray-500 sm mr-3 transparent w-10 justify-center" v-on:click="add(30)">+30</button>
                <button class="btn inline-flex items-center font-bold rounded-md focus:outline-none bg-gray-500 text-gray-500 sm mr-3 transparent w-10 justify-center" v-on:click="subtract(5)">-5</button>
                <button class="btn inline-flex items-center font-bold rounded-md focus:outline-none bg-gray-500 text-gray-500 sm mr-3 transparent w-10 justify-center" v-on:click="subtract(10)">-10</button>
                <button class="btn inline-flex items-center font-bold rounded-md focus:outline-none bg-gray-500 text-gray-500 sm mr-3 transparent w-10 justify-center" v-on:click="subtract(30)">-30</button>
                <button-b type="button" @click.native="$emit('emitedTime', time)" class="bg-primary sm mr-3">{{ $t('dashboard.transfer') }}</button-b>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonB from '../ui/ButtonB.vue'
export default {
    components: { ButtonB },
    data () {
        return {
            time: 10,
            color: false
        }
    },
    methods: {
        add: function (inc) {
            this.color = true
            if (this.time + inc <= 120) {
                this.time += inc
            }
            setTimeout(() => { this.color = false }, 200)
        },
        subtract: function (dec) {
            this.color = true
            if (this.time - dec >= 0) {
                this.time -= dec
            }
            setTimeout(() => { this.color = false }, 200)
        }
    },
    props: {
        order: {
            type: Object
        }
    }
}
</script>

<style>

</style>
