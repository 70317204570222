<template>
    <div class="bg-white rounded-lg mb-10">
        <div class="flex flex-wrap">
            <!-- col 1 -->
            <div class="flex flex-col justify-between p-10 w-4/5">
                <div class="mb-8">
                    <div class="flex flex-col mb-5">
                        <div class="mt-2 flex items-center mb-2">
                            <badge settings="bg-gray-500">
                                {{finalOrder.name}}
                            </badge>
                        </div>
                        <div class="flex items-center">
                            <h4>{{finalOrder.shipping.firstName}} {{finalOrder.shipping.name}}</h4>
                            <badge v-if="finalOrder.method.method === 'delivery'" class="ml-3" settings="bg-yellow">
                                {{$t(`dashboard.${finalOrder.method.method}`)}}
                            </badge>
                            <badge v-else class="ml-3" settings="bg-blue">
                                {{$t(`dashboard.${finalOrder.method.method}`)}}
                            </badge>
                        </div>
                        <span>{{finalOrder.shipping.street}} {{finalOrder.shipping.streetNumber}}</span>
                        <span>{{finalOrder.shipping.postalCode}} {{finalOrder.shipping.city}}</span>
                        <span>{{finalOrder.shipping.phoneNumber}}</span>
                        <span>{{finalOrder.shipping.mail}}</span>
                        <div v-if="finalOrder.paymentMethod === 'paypal'" class="mt-2 flex items-center">
                            <strong class="text-black text-sm">{{$t('dashboard.already_payed_with')}}</strong>
                            <badge class="ml-2" settings="bg-blue">
                                {{'PayPal'}}
                            </badge>
                        </div>
                        <div v-if="finalOrder.paymentMethod === 'cash'" class="mt-2 flex items-center">
                            <strong class="text-black text-sm">{{$t('dashboard.paymentMethod')}}</strong>
                            <badge class="ml-2" settings="bg-purple">
                                {{$t('dashboard.cash')}}
                            </badge>
                        </div>
                        <div v-if="finalOrder.paymentMethod === 'localec'" class="mt-2 flex items-center">
                            <strong class="text-black text-sm">{{ $t('dashboard.paymentMethod') }}</strong>
                            <badge class="ml-2" settings="bg-purple">
                                {{ $t('dashboard.localec') }}
                            </badge>
                        </div>
                        <div v-if="finalOrder.paymentMethod === 'accountid'" class="mt-2 flex items-center">
                            <strong class="text-black text-sm">{{ $t('dashboard.paymentMethod') }}</strong>
                            <badge class="ml-2" settings="bg-purple">
                                {{ $t('dashboard.accountid') }}
                            </badge>
                        </div>
                        <div v-if="finalOrder.timeType.timed && finalOrder.deliveryTime" class="text-red">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <strong>{{$t('dashboard.terminated_order')}}</strong>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex flex-col mr-5">
                            <div v-if="!finalOrder.timeType.timed || !finalOrder.deliveryTime" class="flex flex-col">
                                <span class="text-sm mb-1 text-black font-semibold">{{ $t('dashboard.ordered_at') }}</span>
                                <badge settings="bg-gray-600 transparent">
                                    {{(finalOrder.postedAt).toDate().getHours()}}:{{(finalOrder.postedAt).toDate().getMinutes().toString().padStart(2, '0')}} {{ $t('dashboard.clock') }}
                                </badge>
                            </div>
                            <div v-if="finalOrder.timeType.timed && finalOrder.deliveryTime" class="flex flex-col">
                                <span class="text-sm mb-1 text-black font-semibold">{{ $t('dashboard.ordered_for') }}</span>
                                <badge settings="bg-red text-red transparent">
                                    {{ $t(`dashboard.day_${finalOrder.deliveryTime.getDay()}`)+ ', ' + finalOrder.deliveryTime.getDate() + '.' + (finalOrder.deliveryTime.getMonth()+1) + '.' + finalOrder.deliveryTime.getFullYear() + ' ' + finalOrder.deliveryTime.getHours() + ':' + finalOrder.deliveryTime.getMinutes().toString().padStart(2, '0')}} {{ $t('dashboard.clock') }}
                                </badge>
                            </div>
                        </div>
                        <div v-if="(orderState !== 'POSTED') && (orderState !== 'DRAFT') && (orderState !== 'ACCEPTED')" class="flex flex-col">
                            <span class="text-sm mb-1 text-black font-semibold">{{$t(`dashboard.${finalOrder.method.method}`)}} {{'um'}}</span>
                            <badge settings="bg-primary transparent">
                                {{(finalOrder.finishedTime).toDate().getHours()}}:{{(finalOrder.finishedTime).toDate().getMinutes().toString().padStart(2, '0')}} {{ $t('dashboard.clock') }}
                            </badge>
                        </div>
                    </div>
                </div>
                <div v-show="finalOrder.extraWish !== '' || undefined" class="">
                    <h6>{{ $t('dashboard.extra_request') }}</h6>
                    <p class="whitespace-pre-wrap">{{finalOrder.extraWish}}</p>
                </div>
                <div class="mt-2" v-if="(finalOrder.discounts.length === 1)">
                    <h6>{{ $t('dashboard.used_1discount') }}</h6>
                    <p class="text-black">{{finalOrder.discounts[0].code}}</p>
                </div>
                <div class="mt-2" v-if="(finalOrder.discounts.length > 1)">
                    <h6>{{ $t('dashboard.used_discounts') }}</h6>
                    <li class="text-black" v-for="discount in finalOrder.discounts" :key="discount.uuid">
                        {{discount.code}}
                    </li>
                </div>
            </div>
            <!-- col 3 -->
            <div class="flex flex-col w-1/5 justify-between">
                <div class="p-10">
                    <div class="flex justify-end">
                        <!---
                        <a class="text-gray-600 hover:text-primary mr-5 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" /></svg>
                        </a>--->
                        <a @click="$emit('showChat')" class="text-gray-600 hover:text-primary cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" /></svg>
                        </a>
                    </div>
                </div>
                <div class="p-10 text-right">
                    <span class="font-headline font-bold text-primary text-2xl">{{$n(finalOrder.orderPrice /100, 'currency')}}</span>
                </div>
            </div>
            <!-- col 2 -->
            <div class="flex flex-col justify-between w-full px-10">
                <div class="pb-5 flex flex-wrap">
                    <div v-if="orderState === 'POSTED'" @click="acceptOrder" class="hover:bg-white hover:text-red-light p-2 border-2 border-red-light bg-red-light text-white border-double rounded-md mb-5 cursor-pointer w-full inline-flex items-center justify-center">
                            <span class="mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" class=" h-8 w-8" style="display: inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </span>
                            <strong class="text-m">{{$t('dashboard.not_yet_accepted')}}</strong>
                        </div>
                    <div v-if="orderState === 'ACCEPTED'" class="border-2 border-green bg-green text-white rounded-md p-2 mb-7">
                            <span class="mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" style="display: inline-block" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                                </svg>
                            </span>
                            <strong class="">{{$t('dashboard.accepted_now_transmit_time')}}</strong>
                            <div class="mt-2">
                                <completed-in :order="finalOrder" @emitedTime="setDeliveryTime" :time="time"/>
                            </div>
                    </div>
                    <div v-if="finalOrder.finishedTime" class="w-full mb-7">
                            <span v-if="(orderState !== 'POSTED') && (orderState !== 'DRAFT') && (orderState !== 'ACCEPTED')">
                                <span class="text-green mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" style="display: inline-block" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>
                                </span><strong>{{$t('dashboard.accepted_and_time_transmitted')}}</strong>
                            </span>
                            <br/>
                            <span v-if="finalOrder.method.method === 'delivery' && ((orderState === 'INDELIVERY') || (orderState === 'FINISHED') )" class="w-full mt-2 mb-3">
                                <span class="text-yellow mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="display: inline-block" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                        <path d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7a1 1 0 00-1 1v6.05A2.5 2.5 0 0115.95 16H17a1 1 0 001-1v-5a1 1 0 00-.293-.707l-2-2A1 1 0 0015 7h-1z" />
                                    </svg>
                                </span>
                                <strong>{{$t('dashboard.order_in_delivery')}}</strong>
                            </span>
                            <span v-if="finalOrder.method.method === 'pick-up' && ((orderState === 'READYFORPICKUP') || (orderState === 'FINISHED'))" class="w-full mt-2 mb-3">
                                <span class="text-blue">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" style="display: inline-block" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </svg>
                                </span>
                                <strong>{{$t('dashboard.order_ready_for_pickup')}}</strong>
                            </span>
                            <div v-if="finalOrder.method.method === 'delivery' && orderState === 'APPROVED'" class="hover:bg-white bg-yellow border-2 text-white hover:text-yellow border-yellow rounded-md w-full inline-flex items-center justify-center cursor-pointer mt-2 p-2" @click="orderInDelivery">
                                <span class="mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="display: inline-block" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
                                    </svg>
                                </span>
                                <strong class="">{{$t('dashboard.order_in_preparation_delivery')}}</strong>
                            </div>
                            <div v-if="finalOrder.method.method === 'pick-up' && orderState === 'APPROVED'" class="border-2 hover:bg-white hover:text-blue border-blue bg-blue text-white rounded-md w-full inline-flex items-center justify-center cursor-pointer mt-2 p-2" @click="orderReady">
                                <span class="mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="display: inline-block" class="h-7 w-7" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                                    </svg>
                                </span>
                                <strong class="">{{$t('dashboard.order_in_preparation_pickup')}}</strong>
                            </div>
                        </div>
                <div class="p-4 bg-gray-50 rounded-t-lg w-full">
                    <div class="grid grid-cols-2">
                        <ordered-products v-for="lineItem in finalOrder.lineItems" :lineItem="lineItem" :key="lineItem.uuid"/>
                    </div>
                </div>
                <div class="w-full mt-7">
                            <div v-if="finalOrder.method.method === 'delivery'">
                            <div v-if="!(orderState === 'FINISHED')" type="buton" :class="{'bg-gray-500 opacity-50 border-gray-500 pointer-events-none': !(orderState === 'INDELIVERY')}" class="bg-green hover:bg-white hover:text-green border-2 border-green btn inline-flex items-center font-bold rounded-md shadow-sm text-white focus:outline-none cursor-pointer w-full justify-center" @click="orderFinished">
                                {{ $t('dashboard.order_delivered') }}
                            </div>
                            <span v-if="orderState === 'FINISHED'" style="margin-left: auto; margin-right: auto" class="text-green w-auto mt-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="display: inline-block" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <strong class="text-lg">{{$t('dashboard.order_finished')}}</strong>
                            </span>
                        </div>
                        <div v-if="finalOrder.method.method === 'pick-up'">
                            <div v-if="!(orderState === 'FINISHED')" type="buton" :class="{'bg-gray-500 opacity-50 border-gray-500 pointer-events-none': !(orderState === 'READYFORPICKUP') }" class="bg-green hover:bg-white hover:text-green border-2 border-green btn inline-flex items-center font-bold rounded-md shadow-sm text-white focus:outline-none cursor-pointer w-full justify-center" @click="orderFinished">
                                {{$t('dashboard.order_picked_up')}}
                            </div>
                            <span v-if="orderState === 'FINISHED'" style="margin-left: auto; margin-right: auto" class="text-green w-auto mt-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="display: inline-block" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <strong class="text-lg">{{$t('dashboard.order_finished')}}</strong>
                            </span>
                        </div>
                        <div v-if="(orderState === 'FINISHED')" type="buton" class="border-blue border-2 bg-blue hover:text-blue hover:bg-white btn inline-flex items-center font-bold rounded-md shadow-sm text-white focus:outline-none cursor-pointer w-full justify-center mt-2" @click="orderArchived">
                            <svg xmlns="http://www.w3.org/2000/svg" style="display: inline-block" class="h-8 w-8 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                            </svg>
                                {{$t('dashboard.archive_order')}}
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Badge from '../ui/Badge.vue'
import OrderedProducts from './OrderedProducts.vue'
import CompletedIn from './CompletedIn.vue'

export default {
    data () {
        return {
            currentOrder: {},
            time: 0,
            deliveryTime: 0,
            finalTime: 0,
            finalOrder: {}
        }
    },
    props: {
        orderUuid: {
            type: String
        },
        modal: {
            type: Boolean
        }
    },
    components: { Badge, OrderedProducts, CompletedIn },
    name: 'ProductCard',
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        },
        location () {
            return this.$route.query.location
        },
        terminated () {
            return this.$route.query.terminated
        },
        orderState () {
            return this.finalOrder.state
        }
    },
    watch: {
        location: {
            immediate: true,
            handler () {
                this.$emit('loc', this.location)
            }
        },
        terminated: {
            immediate: true,
            handler () {
                this.$emit('terminated', this.terminated)
            }
        },
        restaurant: {
            immediate: true,
            async handler () {
                this.finalOrder = await this.restaurant.getReactiveRenderedOrder(this.orderUuid)
            }
        }
    },
    methods: {
        async setDeliveryTime (time) {
            var date = new Date().getTime()
            date += time * 60000
            this.deliveryTime = new Date(date)
            await this.restaurant.updateOrderTime(this.finalOrder, this.deliveryTime, 'APPROVED')
        },
        async acceptOrder () {
            await this.restaurant.updateOrderState(this.finalOrder, 'ACCEPTED')
        },
        async orderInDelivery () {
            await this.restaurant.updateOrderState(this.finalOrder, 'INDELIVERY')
        },
        async orderReady () {
            await this.restaurant.updateOrderState(this.finalOrder, 'READYFORPICKUP')
        },
        async orderFinished () {
            if ((this.orderState === 'INDELIVERY') || (this.orderState === 'READYFORPICKUP')) {
                await this.restaurant.updateOrderState(this.finalOrder, 'FINISHED')
            }
        },
        async orderArchived () {
            await this.restaurant.updateOrderState(this.finalOrder, 'ARCHIVED')
            this.$router.push({ name: 'dashboard-overview', params: { location: this.$route.query.location, terminated: this.$route.query.terminated } })
        }
    }
}
</script>
